import { mapRoute } from "@/entities/map-table/router"
import { createRouter, createWebHistory } from "vue-router"

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/shared/pages/HomeView.vue")
  },
  { path: "map", ...mapRoute },

  {
    path: "/about",
    name: "about",
    component: () => import("@/shared/pages/AboutView.vue")
  },
  {
    path: "/help",
    name: "help",
    component: () => import("@/shared/pages/HelpView.vue")
  },
  {
    path: "/terms-and-conditions",
    name: "termsAndConditions",
    component: () => import("@/shared/pages/TermsConditions.vue")
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () => import("@/shared/pages/PrivacyPolicy.vue")
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})
export default router
