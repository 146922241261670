const settings = {
  defaultLang: "en",
  allowLangs: [
    {
      title: "English",
      value: "en",
      regex: /_en/g
    },
    {
      title: "Español",
      value: "es",
      regex: /_es/g
    }
    // { title: "Français", value: "fr",regex: /_fr/g },
    // { title: "Português", value: "pt",regex: /_pt/g }
  ],
  recommendationIcons: {
    "strong-for-the-intervention": "icon-strong text-successColor text-lg",
    "strong-against-the-intervention":
      "icon-strongAgainst text-dangerColor text-lg",
    "conditional-for-the-intervention":
      "icon-conditional text-successColor text-lg",
    "conditional-against-the-intervention":
      "icon-conditionalAgainst text-dangerColor text-lg"
  },
  recommendationsCategories: {
    COVID: "5e7fce7e3d05156b5f5e032a",
    RECOMMENDATIONS: "64ce3f69cbc4717567b7a87e",
    BIGG_MAP: "63f7cc142bdc5e0008a19575"
  }
}

export default settings
