<template>
	<div class="bg-primaryColor">
		<div class="container">
			<div class="pb-4 md:pb-6 pt-4">
				<div
					class="flex flex-col gap-6 md:flex-row md:justify-between items-center md:items-end">
					<button
						class="flex gap-6 h-10 sm:h-12 md:h-14"
						@click="$router.push({ name: 'home' })">
						<img
							class="h-full"
							:src="
								require(`@/assets/images/${langStore.lang.toLocaleLowerCase()}/bvs_logo.svg`)
							">
						<div class="w-[1px] h-full border-l" />
						<img
							class="h-full"
							:src="
								require(`@/assets/images/${langStore.lang.toLocaleLowerCase()}/logo-bigg-map.svg`)
							">
					</button>
					<div class="flex md:hidden justify-center">
						<img
							class="h-4 sm:h-6"
							:src="
								require(`@/assets/images/${langStore.lang.toLocaleLowerCase()}/ops_logo.svg`)
							">
					</div>

					<div
						class="hidden md:flex md:h-full md:flex-col flex-1 items-end gap-2 justify-center md:justify-between">
						<LangSelector />

						<img
							class="!h-2/5 2xl:!h-1/2 !max-h-full !object-contain"
							:src="
								require(`@/assets/images/${langStore.lang.toLocaleLowerCase()}/ops_logo.svg`)
							">
					</div>
				</div>
			</div>
		</div>
		<div class="bg-breadcrumbColor">
			<NavBar />
		</div>
	</div>
</template>

<script setup>
import LangSelector from "../LangSelector.vue";
import NavBar from "./navBar.vue";
const { useLocaleStore } = require("@/stores/locale");
const langStore = useLocaleStore();
</script>

<style lang="scss" scoped></style>
