import { defineStore } from "pinia"
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
export const useLayoutStore = defineStore("layout", () => {
  const menuMobile = ref(false)
  const { t } = useI18n()
  const links = computed(() => {
    return [
      { name: "home", label: t("misc.home") },
      {
        name: "map",
        label: t("navBar.buttons.mapTable")
      },
      {
        name: "about",
        label: t("navBar.buttons.about")
      },
      {
        name: "help",
        label: t("navBar.buttons.help")
      }
    ]
  })

  const heightHeader = ref(0)
  const heightBreadcrumb = ref(0)

  return { menuMobile, links, heightHeader, heightBreadcrumb }
})
