<template>
	<div class="relative">
		<div class="hidden md:flex list-none w-full container">
			<router-link
				v-for="link in mainStore.links"
				:key="link.name"
				v-slot="{ isActive, href, navigate }"
				:to="{
					name: link.name,
				}">
				<li
					:href="href"
					squared
					:class="{
						'bg-activeColor !text-white': isActive,
						'text-darkBlue': !isActive,
					}"
					class="text-xs xl:text-sm font-medium no-underline px-4 cursor-pointer py-[6px] xl:py-2 hover:bg-activeColor hover:text-white transition-all duration-200"
					variant="link"
					@click="navigate">
					{{ link.label }}
				</li>
			</router-link>
		</div>
		<div class="flex md:hidden">
			<div class="container">
				<button
					class="my-2"
					@click="handleOpenMenuMobile">
					<i class="icon-menu text-darkBlue text-xl" />
				</button>
			</div>
			<div><lang-selector mobile /></div>
		</div>
	</div>
</template>

<script setup>
import LangSelector from "../LangSelector.vue";
import { useLayoutStore } from "@/stores/layout";
const mainStore = useLayoutStore();
const handleOpenMenuMobile = () => {
  mainStore.menuMobile = !mainStore.menuMobile;
};
</script>

<style lang="scss" scoped></style>
