export const mapRoute = {
  name: "map",
  path: "/map-table",
  redirect: {
    name: "mapTable",
    params: {
      interventions: ""
    }
  },

  children: [
    {
      path: ":tableId?/recommendations/:interventionId/:populationId/interventions/:interventions*",
      name: "recommendations",
      component: () =>
        import("@/entities/map-table/pages/RecommendationsView.vue")
    },
    // {
    //   path: ":tableId?/recommendations/:interventionId/:populationId/interventions/:interventions*/recommendation/:recommendationId",
    //   name: "recommendation-more-detail",
    //   component: () =>
    //     import("@/entities/map-table/pages/RecommendationMoreDetailView.vue")
    // },
    {
      path: ":interventions*",
      name: "mapTable",
      component: () => import("@/entities/map-table/pages/MapView.vue")
    }
  ]
}
