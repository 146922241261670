import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import { VueQueryPlugin } from "@tanstack/vue-query"
import "./assets/icons.css"
import { createPinia } from "pinia"
// Vuetify
import "vuetify/styles"
import { aliases, fa } from "vuetify/iconsets/fa"
import { createVuetify } from "vuetify"
import * as components from "vuetify/components"
import * as directives from "vuetify/directives"
import "./assets/tailwind.css"
import VueTippy from "vue-tippy"
import "tippy.js/dist/tippy.css" // optional for styling
import i18n from "./i18n/i18n"

const app = createApp(App)

// Tema de vuetify
const vuetify = createVuetify({
  components,
  directives,
  display: {
    mobileBreakpoint: "sm",
    thresholds: {
      xs: 0,
      sm: 540,
      md: 720,
      lg: 960,
      xl: 1400
    }
  },
  icons: {
    defaultSet: "fa",
    aliases,
    sets: {
      fa
    }
  }
})

app.use(
  VueTippy,
  // optional
  {
    directive: "tippy", // => v-tippy
    component: "tippy" // => <tippy/>
  }
)
app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  }
})
// Add components
app.config.productionTip = false

app.mount("#app")

export { app }
