import { createI18n } from "vue-i18n"
// general
import enGeneral from "@/i18n/en/general.json"
import esGeneral from "@/i18n/es/general.json"
import ptGeneral from "@/i18n/pt/general.json"
import frGeneral from "@/i18n/fr/general.json"
// categories
import enCategories from "@/i18n/en/categories.json"
import esCategories from "@/i18n/es/categories.json"
import ptCategories from "@/i18n/pt/categories.json"
import frCategories from "@/i18n/fr/general.json"

const messages = {
  en: { ...enGeneral, ...enCategories },
  es: { ...esGeneral, ...esCategories },
  pt: { ...ptGeneral, ...ptCategories },
  fr: { ...frGeneral, ...frCategories }
}
const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: ["en", "es", "pt", "fr"],
  availableLocales: ["en", "es", "pt", "fr"],
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages
})

export default i18n
