import settings from "@/shared/utils/settings"
import { defineStore } from "pinia"
import { ref, watch } from "vue"
import { useI18n } from "vue-i18n"
export const useLocaleStore = defineStore("locale", () => {
  const lang = ref(settings.defaultLang)

  const t = useI18n()
  // Observa los cambios en la propiedad 'lang'
  watch(lang, (newLang) => {
    // Actualiza el idioma en la instancia de VueI18n
    t.locale.value = newLang
  })
  return { lang }
})
