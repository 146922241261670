<template>
	<div
		ref="langSelector"
		class="h-full">
		<div
			class="sl-nav h-full"
			:class="{
				'text-darkBlue': props.mobile,
				'text-white ': !props.mobile,
			}">
			<div
				class="nav-button px-4 h-full"
				:class="{
					'bg-[#00285780]  rounded-md': !props.mobile,
					'bg-[#0028570D]': props.mobile,
				}"
				@click="visible = !visible">
				<i
					class="icon-world"
					:class="{ 'text-lg': mobile, 'text-base': !mobile }" />
				<span
					class="font-medium 2xl:text-sm w-full ml-4"
					:class="{ 'text-base': mobile, 'text-sm': !mobile }">{{ currentLang }}</span>
				<i
					class="icon-arrowDown"
					:class="{ 'text-xl': mobile, 'text-lg': !mobile }" />
			</div>
			<div
				class="relative"
				:class="{
					'top-2': !mobile,
				}">
				<div
					v-if="visible"
					class="arrow" />
				<div
					v-if="visible"
					class="lang-list overflow-hidden"
					:class="{
						'w-screen rounded-b-md shadow-lg': props.mobile,
						' w-[120%] rounded-sm shadow-lg': !props.mobile,
					}">
					<div
						v-for="lang in settings.allowLangs"
						:key="lang.value"
						class="lang-item"
						@click="changeLang(lang.value)">
						<!-- <img class="lang-icon" :src="require(`@/assets/icons/${lang.value}.svg`)" /> -->
						<span>{{ lang.title }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useLocaleStore } from "@/stores/locale";
import settings from "@/shared/utils/settings";
import { onClickOutside } from "@vueuse/core";
const langStore = useLocaleStore();
const visible = ref(false);
const langSelector = ref(null);

const props = defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
});
const currentLang = computed(
  () => settings.allowLangs.find((el) => el.value === langStore.lang).title
);

const changeLang = (lang) => {
  langStore.lang = lang;
  visible.value = false;
};

onClickOutside(langSelector, () => {
  if (visible.value) {
    visible.value = false;
  }
});
</script>
<style lang="scss" scoped>
.sl-nav {
  width: 160px;
  position: relative;
  .nav-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .lang-list {
    cursor: pointer;
    z-index: 999;
    position: absolute;
    background-color: white;

    right: 0;
    flex-direction: column;

    .lang-item {
      width: 100%;
      overflow: hidden;
      padding: 10px 20px;
      border-bottom: 0.5px solid #a5a5a5;
      font-weight: 400;
      color: #464647;
      display: flex;
      gap: 10px;
      &:last-child {
        border-bottom: initial;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }
  .arrow {
    content: "";
    position: absolute;
    top: -9px;
    right: 15px;
    border-width: 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent white;
  }
}
</style>
