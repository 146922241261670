<template>
	<v-app>
		<div class="sticky top-0 z-40">
			<HeaderComponent ref="header" />
		</div>
		<router-view />
	</v-app>
</template>

<script setup>
import HeaderComponent from "@/shared/components/layout/HeaderComponent.vue";
import { useElementSize } from "@vueuse/core";
import { ref, watch } from "vue";
import { useLayoutStore } from "./stores/layout";

const layout = useLayoutStore();
const header = ref(null);
const { height: heightHeader } = useElementSize(header);
watch(heightHeader, (value) => {
  layout.heightHeader = value;
});
</script>

<style lang="scss" scoped></style>
